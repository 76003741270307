body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@media (max-width: 768px) {
  #nav-btns {
    display: none;
  }
}

@media (min-width: 768px) {
  #nav-sandwich {
    display: none;
  }
}

div.MuiDrawer-paperAnchorLeft {
  background-color: #002e42 !important;
}

